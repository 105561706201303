/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23-02-2019.
 */
import React, { Component } from 'react';
import { SignInInstead } from "./SignInInstead";
import { Link } from "react-router-dom";

export default class BrandRegister extends Component {

	render(){

		return (
		   <div className="col-10 m-auto col-md-5 ">
			   <div className=" content-box " style={{background:"white", padding:"2rem", marginTop:50}}>
				   <div>

					   <h1>
						   <b>
							   Get acces to firstmover influencers
						   </b>
					   </h1>

				   </div>
				   {this.props.loginForm}

				   <SignInInstead label="I'm a Influencer" to="/login/register" />


				   {/*<div className="terms" style={{marginTop:5}}>
					   <p style={{color:"grey"}}>
						   By signing up, you agree to Finewires <u>Terms of Use</u> and <u>Privacy Policy</u>
					   </p>
				   </div>*/}
			   </div>

		   </div>
		)
	}

}
