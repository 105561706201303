/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30-11-2018.
 */

import createContext from 'immer-wieder'
import { getFeatured } from '../service/Api'
import { getPrice } from "../Constants";

const {Provider, Consumer} = createContext(createStore);

export const BrandProvider = Provider;
export const BrandConsumer = Consumer;

function createStore(setState, getState){

	console.log(" Store.js > INIT BRAND Store  ");

	const obj = {

		brand:{
			test:true,
		},

		campaigns:{
			data:[]
		},

		selectedCampaign:null,

		featured:{
			data:[]
		},

		assignments:[],
		assignmentsFactor:0,

		currentCampaign:{},

		searchInfluencers:{
			selected:[],
			reach:0,
			cost:0,
			avgScore:0
		},


		actions:{


			updateCampaignById:function (campaign){

				//campaign
				getState(state => {

					const updated = state.campaigns.data.map((item, index) => {
						return item._id === campaign._id ? campaign : item
					});

					setState(state => void (state.campaigns.data = updated))

				})

			},


			initBrand:function (brand, campaigns){
				console.log(" UserStore.js > initBrand = ", brand);
				console.log (" BrandStore.js > ------------------------------------------");
				console.log (" BrandStore.js > campaigns = " , campaigns);

				//setState(state => void (state.brand = brand, state.campaigns.data = campaigns))

				setState(state => {
					state.brand = brand;
					state.campaigns.data = campaigns
				})
			},

			getFeatured:function (type){
				getFeatured(type).then(
				   (res) => {

					   if (res.data){
						   setState(state => {
							   state.featured.data     = res.data
						   });
					   }
				   }
				)
			},


			setAssignments: function (arr){


				const assignmentsFactor = arr.reduce(function(prevVal, item) {
					const v = (item.faktor * item.amount);
					console.log (" BrandStore.js > v = " , v);
					return prevVal + v;
				}, 0);


				getState( state => {

					const selectedInfluencerArr = state.searchInfluencers.selected;
					// we must use the asignmentFactor which is calculated above, otherwise we will always be one behind
					//const assignmentsFactor = asignmentFactor;

					const reach = selectedInfluencerArr.reduce(function(prevVal, item) {
						return prevVal + item.totalFollowers;
					}, 0);

					const avgScore = selectedInfluencerArr.reduce(function(prevVal, item) {
						return prevVal + item.rating.score;
					}, 0);

					const cost = selectedInfluencerArr.reduce(function(prevVal, item) {
						const p = getPrice(item, assignmentsFactor);
						console.log (" BrandStore.js > p = " , p);
						return prevVal + p ;
					}, 0);

					console.log (" BrandStore.js > cost = " , cost);

					const avgScoreSafe = selectedInfluencerArr.length === 0 ? 0 : avgScore / selectedInfluencerArr.length;


					setState(state => {
						// handle assigments
						state.assignments = arr;
						state.assignmentsFactor = assignmentsFactor;

						// handle stats calculations
						state.searchInfluencers.avgScore = avgScoreSafe;
						state.searchInfluencers.reach = reach;
						state.searchInfluencers.cost = cost;
					})

				})

				/*setState(state => {
					state.assignments = arr;
					state.assignmentsFactor = Math.round(sum);
				})*/

			},


			updateSelectedStats:function (){

				/*getState( state => {

					console.log (" BrandStore.js > state = " , state);

					const selectedInfluencerArr = state.searchInfluencers.selected;
					const assignmentsFactor = state.assignmentsFactor;

					console.log (" BrandStore.js > selectedInfluencerArr = " , selectedInfluencerArr);

					const reach = selectedInfluencerArr.reduce(function(prevVal, item) {
						return prevVal + item.totalFollowers;
					}, 0);

					const avgScore = selectedInfluencerArr.reduce(function(prevVal, item) {
						return prevVal + item.rating.score;
					}, 0);

					const cost = selectedInfluencerArr.reduce(function(prevVal, item) {
						const p = getPrice(item, assignmentsFactor);
						return prevVal + p ;
					}, 0);


					const avgScoreSafe = selectedInfluencerArr.length === 0 ? 0 : avgScore / selectedInfluencerArr.length;

					console.log (" BrandStore.js > cost = " , cost);

					setState(state => {
						state.searchInfluencers.avgScore = avgScoreSafe;
						state.searchInfluencers.reach = reach;
						state.searchInfluencers.cost = cost;
					})

				})
*/

			},

			updateSearchInfluencers: function (arr, assignmentsFactor){

/*				selected:[],
				   reach:0,
				   cost:0,
				   avgScore:0
				   */


				const reach = arr.reduce(function(prevVal, item) {
					return prevVal + item.totalFollowers;
				}, 0);

				const avgScore = arr.reduce(function(prevVal, item) {
					return prevVal + item.rating.score;
				}, 0);

				const cost = arr.reduce(function(prevVal, item) {
					const p = getPrice(item, assignmentsFactor);

					return prevVal + p ;
				}, 0);


				//getPrice()

				//Math.round(num * 100) / 100

				const avgScoreSafe = arr.length === 0 ? 0 : avgScore / arr.length;

				setState(state => {
					state.searchInfluencers.selected = arr;
					state.searchInfluencers.avgScore = avgScoreSafe;
					state.searchInfluencers.reach = Math.round(reach);
					state.searchInfluencers.cost = Math.round(cost);
				})
			},


		}

	};

	return obj;

}
