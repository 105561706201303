/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30-11-2018.
 */

import { Auth, Hub } from 'aws-amplify';
import createContext from 'immer-wieder'
const {Provider, Consumer} = createContext(createStore);

export const AuthProvider = Provider;
export const AuthConsumer = Consumer;


function createStore(setState, getState){

	console.log(" Store.js > INIT AuthStore  ");

	const hubListner = {
		onHubCapsule:(capsule) => {

			console.log (" AuthStore.js > EVENT = " , capsule.payload);

			switch (capsule.payload.event) {
				case 'signIn':
					//setState(state => { state.auth.isAuth = true });
					validateUser(setState);


					break;
				case 'signUp':

					break;
				case 'signOut':
					console.log(" Store.js > SIGN OUT = ");
					setState(state => { state.auth.isAuth = false });
					break;

				case 'signIn_failure':

					setState(state => {
						//state.auth.userNotConfirmed = true

						state.auth.signIn_failure.code = capsule.payload.data.code
						state.auth.signIn_failure.message = capsule.payload.data.message
					});

					break;

				case 'configured':
					setState(state => {
						state.auth.configured = true
					});

					validateUser(setState);

					break
			}
		}
	};

	Hub.listen('auth', hubListner);

	const obj = {

		auth:{
			readyState:false,
			userNotConfirmed:false,
			configured:false,
			isAuth:false,
			profile:"",
			signIn_failure:{
				code:"",
				message:""
			}
		},

		actions:{
			signout:function (history){
				Auth.signOut()
				   .then(data => {
					   console.log (" AuthStore.js > signout = " , data);
					   history.push("/login")
				   })
				   .catch((err) => {
					   console.log (" AuthStore.js > signout ERROR = " , err);
				   });
			}
		}

	};

	return obj;

}




function validateUser(setState){

	Auth.currentSession().then(
	   (res) => {
		   //console.log(" Authstore.js > currentSession = ", res);

		   let isAuth = res && res.idToken ? true : false
		   if (res && res.idToken){
			   //console.log(" Store.js > HAS ID TROKEN = ");

		   } else {
			   console.log(" UserService.js > ---------------------------");
			   console.log(" UserService.js > ERROR - NO idToken ", res);
			   console.log(" UserService.js > ---------------------------");
		   }

		   setState(state => {
			   state.auth.readyState = true;
			   state.auth.isAuth = isAuth;
			   state.auth.profile = res.idToken.payload.profile;
		   })

		   return res
	   }
	).catch((err) => {

		console.log(" UserService.js > ---------------------------");
		console.log(" UserService.js > No current session = ", err);
		console.log(" UserService.js > ---------------------------");
		setState(state => {
			state.auth.readyState = true
			state.auth.isAuth     = false
		})
	});

}


