import Amplify from 'aws-amplify';
//amplify federationTarget:
export function configureCognito (){
	Amplify.configure({
		Auth: {

			region: process.env.REACT_APP_AWS_REGION,
			userPoolId:process.env.REACT_APP_AWS_POOL_ID,

			// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
			userPoolWebClientId: process.env.REACT_APP_AWS_CLIENT_ID,

			// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
			mandatorySignIn: true,

			/*identityPoolRegion: 'eu-central-1',
			identityPoolId: 'eu-central-1:68de6d2c-39ef-44bf-9b2b-1a98835b31ce',*/

			// OPTIONAL - Configuration for cookie storage
			/*cookieStorage: {
				// REQUIRED - Cookie domain (only required if cookieStorage is provided)
				domain: '.yourdomain.com',
				// OPTIONAL - Cookie path
				path: '/',
				// OPTIONAL - Cookie expiration in days
				expires: 365,
				// OPTIONAL - Cookie secure flag
				secure: true
			},
	*/
			// OPTIONAL - customized storage object
			//storage: new MyStorage(),

			// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
			authenticationFlowType: 'USER_PASSWORD_AUTH'
		}
	});
}
