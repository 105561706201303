/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04-12-2018.
 */

import { ApiGet, ApiPost, apiPostForm } from './ApiGateway'


// This is shared -

export async function userSignIn(data){
	return await ApiPost("/user/signin", data )
}

export async function getUser(){
	return ApiGet("/user");
}

export async function getCommunities(){
	return ApiGet("/api/communities");
}


export function updateUserProfile(data){
	return ApiPost("/user/profile", data);
}


export function getMarketplace(){
	return ApiGet("/api/marketplace" )
}


export function getFeatured(type){
	return ApiGet('/api-shared/featured?type=' + type)
}





