/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 15-05-2019.
 */

import React, { Component } from 'react';
import { Link } from "react-router-dom";

export const SignInInstead = (props) => {

	const { label , to } = props

	const alignClass = label ?  "justify-content-between" :" justify-content-center "

	return (
	   <div className={`d-flex flex-row ${alignClass}`} >

		   <Link to="/login">
			   <p className="text-center text-secondary" style={{marginTop:10, marginBottom:0, fontSize:14, opacity:1 }}>
				   <u>Sign in instead</u>
			   </p>
		   </Link>

		   {label ?
		   (<Link to={to}>
			   <p className="text-center text-secondary" style={{marginTop:10, marginBottom:0, fontSize:14, opacity:1 }}>
				   <u>{label}</u>
			   </p>
		   </Link>) : null

		   }

	   </div>

	)

}

