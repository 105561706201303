/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07-12-2018.
 */

import React from "react";
import { getCommunities } from "./service/InfluencerAPI";

export const DEBUG = process.env.REACT_APP_DEBUG === "true" ? true : false

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const AWS_BASE_PATH = process.env.REACT_APP_AWS_BASE_PATH;
export const INSTAGRAM_CLIENT_ID = process.env.REACT_APP_INSTAGRAM_CLIENT_ID;
export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_ID_ID;
export const PRIMARY_COLOR = "#0033c7";

export const FACEBOOK_PERMISSIONS = 'public_profile, email,user_friends, pages_show_list, instagram_basic, instagram_manage_insights ';
export const BOARDING_THRESHOLD = process.env.REACT_APP_BOARDING_THRESHOLD;

export const INFLUENCER_TYPE = "influencer";
export const BRAND_TYPE = "brand";






export const getAge = (yob  ) => {


		if (!yob){
			return "?"
		}

		const age = new Date().getFullYear() - yob;
		return age;

}

export const getPrice = (influencer, assignmentFactor  ) => {
	//const influencer       = this.props.influencer;
	const score            = influencer.rating.score;
	//const assignmentFactor = this.props.assignmentFactor

	const followers = influencer.totalFollowers;

	const factor         = score > 3 && score < 4 ? 0.85 : score > 4 ? 1 : 0.7;
	const followerFactor = followers > 10000 && followers < 25000 ? 0.035 : followers > 25000 && followers < 50000 ? 0.03 : followers > 50000 ? 0.02 : 0.04
	const total          = assignmentFactor > 0 ? Math.round(750 + 250 + (followerFactor * followers * factor * assignmentFactor)) : 0;

/*
	4-5= faktor 1
	3-4=faktor 0,85
	2-3=faktor 0,7

	Opgave faktor
	1 stk alm post= faktor 1
	1 stk story= faktor 1,5

	3000	0,04
	10000	0,035
	25000	0,03
	50000	0,02

	250 + (0,03 * 40.000 * 0,85 * 1)
*/


	return total
};

export const ASSIGNMENTS_TYPE = {

	instagram:[
		{
			title:"Instagram post",
			id:"in-post",
			faktor:1
		},

		{
			title:"Instagram video",
			id:"in-video",
			faktor:1.3
		},

		{
			title:"Instagram story",
			id:"in-story",
			faktor:1.2
		}
	],

	facebook:[
		{
			title:"Facebook post",
			id:"fb-post",
			faktor:1
		},

		{
			title:"Facebook video",
			id:"fb-video",
			faktor:1.2
		},

		{
			title:"Facebook story",
			id:"fb-story",
			faktor:1.1
		}
	],

	event:[
		{
			title:"Attend Event",
			id:"event-attend",
			faktor:2
		}
	]
}



let communitiesCache = null;
export function getAllCommunities () {
	return communitiesCache ||( communitiesCache = getCommunities())
	   /*.then((res) => {
		return res
	}))*/
}





