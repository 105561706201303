/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04-12-2018.
 */

import { ApiGet, ApiPost, apiPostForm } from './ApiGateway'

export async function userSignIn(data){
	return await ApiPost("/user/signin", data)
}

export async function getUser(){
	return ApiGet("/user");
}

export async function getCommunities(){
	return ApiGet("/api/communities");
}

export function updateUserProfile(data){
	return ApiPost("/user/profile", data);
}

export function getMarketplace(){
	return ApiGet("/api/marketplace")
}

export function setTags(data){
	return ApiPost("/api/tags", data);
}

export function setCommunities(data){
	return ApiPost("/api/communities", data);
}

export function syncFacebook(res){
	return ApiPost('/api/facebook', res)
}

export function getQuestions(res){
	return ApiGet('/api/questions')
}

export function setQuestions(arr){
	return ApiPost('/api/questions', arr)
}

export function answerInvite(obj){
	return ApiPost('/api/invite-answer', obj)
}

export function uploadGalleryImage(form){
	return apiPostForm('/api-upload/profile', form)
}

export function updateGallery(file){
	return ApiPost('/user/update-gallery', file)
}


export function getSocial(){
	return ApiGet('/user/get-social')
}


export function getBoardingQuestion(){
	return ApiGet('/user/boarding-questions')
}

export function saveBoardingQuestion(answerObj){
	return ApiPost('/user/boarding-questions', answerObj)
}

export function boardingQuestionResult (){
	return ApiGet('/user/boarding-question-result')
}

export function boardingCompleted (data){
	return ApiPost('/user/boarding', data)
}

export function getLookAlike (){
	return ApiGet('/api/lookalike')
}

export function setFeaturedImage (data){
	return ApiPost('/user/featured-image', data)
}

export function getFront (){
	return ApiGet('/api/front')
}
