/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30-11-2018.
 */

import createContext from 'immer-wieder'
import { setCommunities, updateGallery, setTags } from "../service/InfluencerAPI";

const {Provider, Consumer} = createContext(createStore);

export const UserProvider = Provider;
export const UserConsumer = Consumer;

function createStore(setState, getState){


 return {

 		isValidated:{
 			status:false,
			missing:[],
			hints:
			   {
					communities:{
						txt:"Please choose a community",
						index:1
					},
				   name:{
					   txt:"Please fill in your name",
					   index:0
				   },
				   gender:{
					   txt:"Please choose a gender",
					   index:0
				   },
				   description:{
					   txt:"Please fill out a description",
					   index:0
				   },
				   country:{
					   txt:"Please select a country",
					   index:0
				   },
				   yob:{
					   txt:"Please select a year of birth",
					   index:0
				   },
				   images:{
					   txt:"Please upload minimum 1 image",
					   index:0
				   },

			   }

		},
		user:{
			test:true,
			images:[],
			tags:[],
			rating:{completed:false},
			community:[],
			info:{

			},
			social:{},
			boarding:{}
		},

		campaigns:{
			data:[]
		},

		actions:{

			initUser:function (user, campaigns){
				setState(state => {
					state.user           = user;
					state.campaigns.data = campaigns
				});
			},


			validateProfile:function (){

				 getState(state => {

					 const missingArr  = Object.keys(state.user.info).filter((key) => {
					 		const item = state.user.info[key];
						 	//console.log (" UserStore.js > item.key = " , item, key);
						 	return item === "" || item === null ? true : false
					 });

					 const missingPhoneIndex = missingArr.indexOf("phone");

					 if( missingPhoneIndex !== -1 ){
						 missingArr.splice(missingPhoneIndex,1);
					 }

					 const missingLocationIndex = missingArr.indexOf("location");
					 if( missingLocationIndex !== -1 ){
						 missingArr.splice(missingLocationIndex,1);
					 }


					 if(!state.user.communities || state.user.communities.length === 0 ){
					 	missingArr.push("communities")
					 }

					 /*if(!state.user.tags || state.user.tags.length === 0 ){
						 missingArr.push("custom tags")
					 }*/

					 if(!state.user.images || state.user.images.length === 0 ){
						 missingArr.push("images")
					 }


					 //
					 // Validate each item, to make sure things have changed
					 //
					 const isEqualArr = missingArr.filter((item, index) => {
						 const current = state.isValidated.missing[index]
						 return current === item ? false : true
					 });

					 const newStatus = missingArr.length === 0 ? true : false

					 //
					 // we need to validate that state has changed otherwise it will loop
					 //
					 if(isEqualArr.length === 0 && state.isValidated.status === newStatus){
					 	console.log (" UserStore.js > dont update  = " );
					 	return
					 }

					 setState(newState => {
						 newState.isValidated.missing = missingArr;
						 newState.isValidated.status = newStatus //missingArr.length === 0 ? true : false
					 });

				})


			},


			getUser:function (){
			/*	getState((state) => {

					console.log (" UserStore.js > state = " , state);

					return state
				})*/
			},
//---------------------------------------------------------------------------------------

			updateUser:function (valueObj){

				setState(state => {
					state.user.info = valueObj
				});
			},

//---------------------------------------------------------------------------------------

			updateQuestions:function (score, values){
				setState(state => {
					state.user.rating.completed = true;
					state.user.rating.score = true;
					state.values = values;
				})
			},

			updateCommunities:function (items){
				console.log(" UserStore.js > Communities: ", items);

				setState(state => {
					state.user.communities = items
				});

			},

			updateImages:(data) => {

				console.log (" UserStore > data = " , data);

				//if (data.file && data.file.status && data.file.status === "removed"){
				if ( data.status && data.status === "removed"){

					console.log (" UserStore > remove it = " );
					updateGallery(data).then((res) => {
						if (res.result){
							const removedUrl = res.data.removed;
							getState((state) => {
								const a = state.user.images.filter((img) => {
									const removedImg = removedUrl.indexOf(img.url) === -1;
									return removedImg ? img : null
								});

								if(a.length === 1 ){
									a[0].featured = true
								}
								console.log (" UserStore > a = " , a);

								setState(state => {
									state.user.images = a
								});

								state.actions.validateProfile()

							})
						}
					})
				}

				if (data.file && data.file.status && data.file.status === "uploading"){
					setState(state => void (state.user.images.push(data.file)))
				}
			},


			addImage:function (doneImg){
				const uid = doneImg.file.uid;

				getState(state => {
					const a = state.user.images.map((item) => {
						if (item.uid === uid){
							item.url    = doneImg.imgUrl
							item.status = "done"
						}
						return item
					});

					setState(state => {
						state.user.images = a
					});

					// if uploading an image while on the boardingValidate
					state.actions.validateProfile()

				})
			},


			setFeaturedImage:function (selectedIndex){
				console.log(" UserStore.js > index = ", selectedIndex);

				getState(state => {
					const a = state.user.images.map((item, index) => {

						item.featured = false;
						if (index === selectedIndex){
							item.featured = !item.featured;
						}

						return item
					});
					setState(state => void (state.user.images = a))

				})
			},


			updateTags:function (tagsArr){
				setState(state => {
					state.user.tags = tagsArr
				});
			},


			updateSocial:function (key, social){
				setState(state => {
					state.user.social[key] = social
				});
			},

			updateBoarding:function (completed, joined){
				setState(state => {
					state.user.boarding.completed = completed;
					state.user.boarding.joined = joined;
				});
			},

			/*getSocial:function (social){

			},*/


			saveTags:function (){
			}

		}

	};


	//return obj;

}
