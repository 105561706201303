/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30-11-2018.
 */

import React, { PureComponent, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { configureCognito } from './config';

import LoginPage from "./components/auth/LoginPage";

import { UserProvider, UserConsumer } from './stores/UserStore'
import { BrandProvider, BrandConsumer } from './stores/BrandStore'
import Spinner from "./components/Spinner";

import FacebookScript from "./components/social-login/FacebookScript";
import ReactGA from 'react-ga';
import { BRAND_TYPE, DEBUG, INFLUENCER_TYPE } from "./Constants";

import { Modal } from "antd";
import { ApiSetErrorhandler } from "./service/ApiGateway";
const confirm = Modal.confirm;

const AsyncBrandApp = React.lazy(() => import('./BrandApp'));
const AsyncApp = React.lazy(() => import('./App'));



ReactGA.initialize('UA-139977803-1',  { testMode: DEBUG, debug:false });


export default class Main extends PureComponent {

	constructor(props){
		super(props);
		this.state = {
			authConfigured:null,
			showError:true
		};

		// ------------
		ApiSetErrorhandler(this.handleApiErrors.bind(this))
	}

	handleApiErrors(res){

		console.log (" Main.js > res = " , res);

		let s = res.err && res.err.message ? res.err.message : JSON.stringify(res.err);

		const ss = res.toString();
		if(s === undefined && ss.search("TypeError: Failed to fetch") === 0 ){
			s = ":( Can't connect to Server"
		}

		confirm({
			title: 'Ooops - something bad happend',
			content: s,
			onOk() {
				return new Promise((resolve, reject) => {

					if (res.err === "401"){
						console.log (" Main.js > YES its 401 = ");
						//this.props.actions.signout()
						//return
					}

					resolve()
				}).catch(() => console.log('Oops errors!'));
			},
			onCancel() {},
		});
	}


	//---------------------------------------------------------------------------------------
	componentDidMount(){
		// When cognito is configured - AuthStore will update
		configureCognito();

	}

	render(){

		const isAuth  = this.props.auth.isAuth;
		//const userNotConfirmed  = this.props.auth.userNotConfirmed;
		const profile = this.props.auth.profile;


		if (!this.props.auth.readyState){
			console.log(" Main.js > WAITING FOR READY STATE  ");
			return <Spinner label="How you doin'?..."/>
		}


		return (

		   <div style={{height:"100%"}}>

			   <Router>
				   <Switch>

					   <Route path="/login" render={props => {

					   		// log traffic
						   ReactGA.pageview(props.location.pathname);
						   	return <LoginPage auth={this.props.auth} routerProps={props}/>

					   	}}/>

					   <Route path="/" render={routerProps => {

						   if (!isAuth){
							   return <Redirect to={"/login"}/>;
						   }


						   if (profile === INFLUENCER_TYPE){
							   return (
								  <UserProvider>
									  <UserConsumer select={store => ({actions:store.actions, user:store.user})}>
										  {
											  ({actions}) => {
												  return (
													 <Suspense fallback={<Spinner />}>
														 <AsyncApp {...routerProps} initUser={actions.initUser} getUser={actions.getUser}
															  signout={this.props.actions.signout} profile={profile} isAuth={isAuth}/>
													 </Suspense>

												  )
											  }
										  }
									  </UserConsumer>
								  </UserProvider>
							   )
						   }


						   if (profile === BRAND_TYPE ){
							   return (
								  <BrandProvider>
									  <BrandConsumer select={store => ({actions:store.actions})}>
										  {
											  ({actions}) => {
												  return (
													 <Suspense fallback={<Spinner label="Loading brand information" />}>
													 <AsyncBrandApp {...routerProps} initBrand={actions.initBrand}
															   signout={this.props.actions.signout} profile={profile} isAuth={isAuth}/>
													 </Suspense>
												  )
											  }
										  }
									  </BrandConsumer>
								  </BrandProvider>
							   )
						   }

						   return <h1>This is not expected - please close the window </h1>

					   }
					   }/>


					   <Route render={(props) => {
						   console.log(" Main.js > CATCH ALL ROUTE = ");
						   return <Redirect to={"/login"}/>
					   }}/>

				   </Switch>
			   </Router>

			   <FacebookScript></FacebookScript>


		   </div>

		)
	}

}
