/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 15-05-2019.
 */
import React, { Component } from 'react';
import { Icon } from "antd";
import './login-form-error-msg.scss'


export default class LoginFormErrorMsg extends Component {

	render(){

		const msg = this.props.children;



		return (
			<div className="mt-1 login-form-error-msg d-flex flex-row">
				<Icon className="mr-1 icon" type="exclamation-circle" theme="filled"  />
				<p className="mb-0 msg">{msg} </p>
			</div>
		)
	}

}
