import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import LoginForm from "./LoginForm";
import ForgotPassword from "./ForgotPassword";
import { Switch, Link, Redirect, Route } from "react-router-dom";
import { userSignIn } from "../../service/Api";

import './login-page.scss'
import logo from "../../assets/logo.svg";
import { Button, Layout } from "antd";
import UserLogin from "../login/UserLogin";
import UserRegister from "../login/UserRegister";
import ValidateEmail from "../login/ValidateEmail";
import BrandRegister from "../login/BrandRegister";
import Signup from "../login/Signup";
import NewPassword from "../login/NewPassword";

export default class LoginPage extends Component {

	state = {
		isAuth:false,
		errorMsg:"",
		validateEmail:false,
		dofb:false
	};

	signUp = (values) => {

		return Auth.signUp({
			username:values.email.toLowerCase(),
			password:values.password,
			attributes:{
				"profile":values.type
			},
		}).then((data) => {

			//this.props.routerProps.history.push("/login/validate");
			return data
		})

	};

	// SignIn
	//---------------------------------------------------------------------------------------

	signIn = (values) => {

		return Auth.signIn(values.email, values.password).then((user) => {
			userSignIn(user.signInUserSession)
			return user
		})
		/*.then(user => {
				// we dont wait for this call to finish
			// this merly update sign in time


		})
		.catch((err) => {
			if (err.code === "UserNotFoundException"){
				this.setState({errorMsg:err.message})
			}
		})*/
	};

	render(){

		if (this.props.auth.isAuth){
			return <Redirect to={"/"}/>;
		}

		const isRegister   = this.props.routerProps.location.pathname === "/login/register";
		const headerBtnTxt = isRegister ? "Log in" : "I'm a Influencer";
		const headerTxt    = isRegister ? "All ready have an account" : "Create a new account";
		const headerTo     = isRegister ? "/login" : "/login/register";

		return (

		   <div className="container-fluid h-100 login-page " style={{paddingRight:0, paddingLeft:0}}>

			   <div className="login-header w-100" style={{position:"relative"}}>

				   <div className="row h-100">

					   <div className="col-12">

						   <div className="d-flex justify-content-between h-100">
							   <div className="logo">
								   <img className="" style={{width:120, marginTop:15, paddingLeft:20}} src={logo}
										alt=""/>
							   </div>

							   {/* <div className="d-flex flex-column justify-content-center h-100 "
									style={{paddingRight:10}}>
								   <div>
									   <span style={{paddingRight:10}}>{headerTxt}</span>

									   <Link to={headerTo}>
										   <Button type="primary">{headerBtnTxt}</Button>
									   </Link>


									   {isRegister ? null :
										  (

												 <Link to="/login/register-brand">
											  <Button className="ml-2" type="">I'm a Brand</Button>
										  	</Link>
										  )
									   }

								   </div>

							   </div>*/}
						   </div>
					   </div>
				   </div>
			   </div>

			   <div className=" d-flex flex-grow-1 row ">

				   <div className="container">

					   <div className="row">
						   <div className="col-12 col-md-6 "></div>

						   <Switch>

							   <Route exact path="/login" render={(props) => (
								  <UserLogin loginForm={
									  <LoginForm routerProps={this.props.routerProps} btnTxt="Log in"
												 errorMsg={this.state.errorMsg}
												 submit={this.signIn}></LoginForm>
								  }></UserLogin>
							   )}/>

							   <Route exact path="/login/validate" render={(props) => (
								  <ValidateEmail></ValidateEmail>
							   )}/>

							   <Route exact path="/login/signup" render={(props) => (
								  <Signup>
								  </Signup>
							   )}/>

							   <Route exact path="/login/new-password" render={(props) => (
								  <NewPassword></NewPassword>
							   )}/>


							   <Route exact path="/login/forgot" render={(props) => (
									<ForgotPassword></ForgotPassword>
							   )}/>

							   <Route exact path="/login/register" render={(props) => (
								  <UserRegister loginForm={
									  <LoginForm routerProps={this.props.routerProps} btnTxt="Sign up as Influencer"
												 errorMsg={this.state.errorMsg}
												 submit={this.signUp}
												 showSelector></LoginForm>

								  }></UserRegister>

							   )}/>

							   <Route exact path="/login/register-brand" render={(props) => (
								  <BrandRegister loginForm={
									  <LoginForm
										 routerProps={this.props.routerProps}
										 btnTxt="Sign up as Brand"
										 type="brand"
										 errorMsg={this.state.errorMsg}
										 submit={this.signUp}
									  ></LoginForm>

								  }></BrandRegister>

							   )}/>


						   </Switch>

					   </div>
				   </div>

			   </div>

		   </div>

		)
	}

}
