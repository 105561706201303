/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23-02-2019.
 */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button } from "antd";
import { SignInInstead } from "./SignInInstead";

export default class Signup extends Component {

	render(){

		return (
		   <div className="col-10 m-auto col-md-5 ">
			   <div className=" content-box " style={{background:"white", padding:"2rem", marginTop:50}}>
				   <div>
					   <p>
						   Great to see you
					   </p>
					   <h1>
						   <b>
							   Are you a Brand or Influencer
						   </b>
					   </h1>

				   </div>

				   <div>
					   <Link to="/login/register-brand">
						   <Button block style={{marginTop:20, height:45}}>I'm a Brand</Button>
					   </Link>
					   <Link to="/login/register">
						   <Button block type="primary" style={{marginTop:10, height:45}} >I'm a Influencer</Button>
					   </Link>

					   <SignInInstead />

				   </div>



				   {/*<div className="terms" style={{marginTop:5}}>
					   <p style={{color:"grey"}}>
						   By signing up, you agree to Firnewires <u>Terms of Use</u> and <u>Privacy Policy</u>
					   </p>
				   </div>*/}
			   </div>

		   </div>
		)
	}

}
