/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 29-11-2018.
 */
import { Formik, Form, Field, ErrorMessage } from 'formik';

import React, { Component } from 'react';
import { validate } from 'email-validator'
import { Button, Radio } from "antd";
import '../inputfields/inputfields.scss'
import { Link } from "react-router-dom";
import LoginFormErrorMsg from "./LoginFormErrorMsg";

const RadioGroup = Radio.Group;

export default class LoginForm extends Component {

	state = {
		type:null,
		errorMsg:"",
		waitingForAuth:false
	};

	onSubmit = (values, {setSubmitting}) => {


		this.setState({waitingForAuth:true});

		values.type = this.props.type === "brand" ? this.props.type : "influencer";

		this.props.submit(values).then((res) => {
			console.log (" LoginForm.js > res = " , res);

			if(!res.userConfirmed ){
				this.setState({waitingForAuth:false})
				this.props.routerProps.history.push("/login/validate");
			}


		})
		   .catch((err) => {

			   console.log(" LoginForm.js > -- err = ", err);
			   this.setState({errorMsg:err.message, name:values.email, pass:values.password, waitingForAuth:false})

		   });

		setSubmitting(false);

	};



	validate = values => {

		let errors = {};

		if (this.state.errorMsg){

			const changed = this.state.name === values.email && this.state.pass === values.password
			this.setState({errorMsg:""})

		}

		if (!values.email){
			errors.email = 'Email is Required';
		}

		if (!validate(values.email)){
			errors.email = 'Invalid email format';
		}

		let passwordErrors = "";

		if (!values.password){
			passwordErrors  = 'A password is Required - ';
			errors.password = passwordErrors
			return errors
		}

		if (!/[a-z]/.test(values.password)){
			passwordErrors += ' it must contain lowercase,';
		}

		if (!/[A-Z]/.test(values.password)){
			passwordErrors += ' it must contain uppercase,';
		}

		if (!/[0-9]/.test(values.password)){
			passwordErrors += ' it must contain number,';
		}

		if (values.password.length < 8){
			passwordErrors += ' it must be more than 8 characters long';
		}

		if (passwordErrors !== ""){
			errors.password = passwordErrors
		}

		return errors;
	};

	onChange = (e) => {
		this.setState({
			type:e.target.value,
		});
	};

	render(){

		const btnTxt = this.props.btnTxt || "btn txt"

		return (

		   <div className="inputfields">
			   {
				   this.props.headline ? <h1>{this.props.headline}</h1> : null
			   }

			   <Formik
				  initialValues={{email:'', password:''}}
				  validate={this.validate}
				  onSubmit={this.onSubmit}
			   >
				   {(bag) => {

					   return (
						  <Form>
							  <Field placeholder="Your email adress" type="email" name="email"/>
							  <ErrorMessage name="email" component={LoginFormErrorMsg}></ErrorMessage>

							  <Field type="password" name="password" placeholder="Your password"/>
							  <ErrorMessage name="password" component={LoginFormErrorMsg}/>

							  {/* {this.props.showSelector ?

							 <div style={{marginTop:10}}>
								 <RadioGroup onChange={this.onChange} value={this.state.type}>
									 <Radio value={0}>Brand</Radio>
									 <Radio value={1}>Influencer</Radio>
								 </RadioGroup>
							 </div>

							 : null}*/}

							  <Button block type="primary" htmlType="submit" loading={this.state.waitingForAuth}
									  style={{marginTop:10, height:45}}>{btnTxt}</Button>

							  {this.state.errorMsg ?

								 <div className="d-flex justify-content-center">
									 <LoginFormErrorMsg>{this.state.errorMsg}</LoginFormErrorMsg>
								 </div>

								 : null}

						  </Form>
					   )
				   }}
			   </Formik>

		   </div>
		);
	}

}




