/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02-05-2019.
 */
import React, { Component } from 'react';
import { FACEBOOK_ID } from "../../Constants";


export default class FacebookScript extends Component {

	componentDidMount(){

		window.fbAsyncInit = () => {
			window.FB.init({
				appId      : FACEBOOK_ID,
				cookie     : true,
				xfbml      : true,
				version    : 'v3.2'
			});

			//this.checkLoginState()
			//window.FB.AppEvents.logPageView();

		};

		(function(d, s, id){
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {return;}
			js = d.createElement(s); js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));

	}



	render(){
		return null
	}

}
