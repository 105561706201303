import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider, AuthConsumer } from './stores/AuthStore'
import * as serviceWorker from './serviceWorker';
import Main from "./Main";
import ReactBreakpoints from "react-breakpoints";
import version from './build-number.json'
import './styles/antd-styles.css';
import './styles/styles.scss';
import './index.scss';


if (process.env.NODE_ENV !== 'production'){
	import ('./styles/size-debug.scss').then((something) => {
	});
}

const breakpoints = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200
};


class Wrap extends React.Component {
	render(){
		console.log (" index.js > version = " , version);
		return (
		   <ReactBreakpoints breakpoints={breakpoints}>
			   <AuthProvider>
				   <AuthConsumer select={store => ({auth:store.auth , actions:store.actions} ) }>
					   {({auth, actions}) => {
						   return (
							  <Main auth={auth} actions={actions}/>
						   )
					   }}

				   </AuthConsumer>
			   </AuthProvider>
		   </ReactBreakpoints>


		);
	}
}

ReactDOM.render(<Wrap/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
