/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 29-11-2018.
 */
import React, { Component } from 'react';
import { ErrorMessage, Field, Form, Formik } from "formik";
import LoginFormErrorMsg from "./LoginFormErrorMsg";
import { Button } from "antd";
import { Auth } from 'aws-amplify';
import '../inputfields/inputfields.scss'
import { Link } from "react-router-dom";

export default class ForgotPassword extends Component {

	state = {
		errorMsg:"",
		mailSentTo:""
	}

	onSubmit = (values) => {

		Auth.forgotPassword(values.email)
		   .then((data) => {

			   console.log(" ForgotPassword.js > data = ", data.CodeDeliveryDetails.Destination);
			   this.setState({mailSentTo:data.CodeDeliveryDetails.Destination})
		   })
		   .catch((err) => {
			   console.log(" ForgotPassword.js > err = ", err);
		   });

	}

	render(){

		return (

		   <div className="col-10 m-auto col-md-5 ">
			   <div className=" content-box " style={{background:"white", padding:"2rem", marginTop:50}}>

				   {

					   this.state.mailSentTo ?

						  <div>
							  <h1>Check your email</h1>
							  <h3>We have sent a mail to {this.state.mailSentTo}</h3>
							  <Link to="/login/new-password">
								  <Button block type="primary"
										  style={{marginTop:10, height:45}}>Create new password</Button>
							  </Link>
						  </div>

						  :

						  (
							 <div className="inputfields">
								 <h1>
									 <b>
										 Reset your password
									 </b>
								 </h1>

								 <Formik
									initialValues={{email:''}}
									validate={this.validate}
									onSubmit={this.onSubmit}
								 >
									 {(bag) => {

										 return (
											<Form>
												<Field placeholder="Your email adress" type="email" name="email"/>
												<ErrorMessage name="email" component={LoginFormErrorMsg}></ErrorMessage>

												<Button block type="primary" htmlType="submit"
														style={{marginTop:10, height:45}}>Reset</Button>

												{this.state.errorMsg ?

												   <div className="d-flex justify-content-center">
													   <LoginFormErrorMsg>{this.state.errorMsg}</LoginFormErrorMsg>
												   </div>

												   : null}

												<div className="d-flex justify-content-between">
													<p style={{paddingTop:10}}>
														<Link to="/login">
															<u>Log in instead</u>
														</Link>
													</p>

													<p style={{paddingTop:10}}>
														<Link to="/login/new-password">
															<u>Have verification code</u>
														</Link>
													</p>

												</div>

											</Form>
										 )
									 }}
								 </Formik>
							 </div>

						  )
				   }

			   </div>
		   </div>

		)
	}

}
