/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30-11-2018.
 */

import { Auth } from 'aws-amplify';
import { BASE_URL } from "../Constants";

const API_ROOT = BASE_URL

let visibleErrorHandler = null;

export function ApiSetErrorhandler(fn){
	visibleErrorHandler = fn
}

//---------------------------------------------------------------------------------------

async function validateToken(){
	const res = await Auth.currentSession();
	//console.log (" ApiGateway.js > validateToken res = " ,  res);
	return res.idToken.jwtToken
}

//---------------------------------------------------------------------------------------
function handleErrors(res){

	console.log(" --------- ");
	console.log(" ApiGateway.js > handleErrors = ", res);
	console.log(" --------- ");


	if(res.status === 401 ){
		console.log (" ApiGateway.js > 401 error  ");

		Auth.currentCredentials().then((creds) => {
			console.log (" ApiGateway.js > Auth.currentCredentials = " );
			console.log (" ApiGateway.js > creds = " , creds);
		});

		visibleErrorHandler({result:false, err:"401"})
		return
	}


	// if the visibleErrorHandler is assigned, it should show a modal
	if (visibleErrorHandler){
		visibleErrorHandler(res)
	}

	// modify the response to align with err response from server
	if (!res.result){
		return {result:false, err:res}
	}

	return res;
}


//---------------------------------------------------------------------------------------
function handleSucces(res){

	if(res.status === 401 ){
		throw res
	}

	return res.json()
}


//---------------------------------------------------------------------------------------


function validateResponse(res){

	// modify the response to align with err response from server
	if (!res.result && visibleErrorHandler){
		visibleErrorHandler(res)
	}


	return res
}

/**
 *
 * @param url
 * @param useCredentials
 * @return {Promise<any | never>}
 *
 *///---------------------------------------------------------------------------------------

export const ApiGet = async (url, useCredentials = true) => {

	const token       = await validateToken();
	const credentials = useCredentials ? getCredentials(token, "GET") : {};
	return fetch(API_ROOT + url, credentials)
	   .then(handleSucces)
	   .catch(handleErrors)
};


/**
 *
 * @param endpoint
 * @param data
 * @param useCredentials
 * @return {Promise<Response | never>}
 * @constructor
 *///---------------------------------------------------------------------------------------


export const ApiPost = async (endpoint, data, useCredentials = true) => {
	const token       = await validateToken();
	const credentials = useCredentials ? getCredentials(token, "POST") : {};

	if(!data ){
		console.log (" ApiGateway.js > YOU DID NOT SEND ANY DATA = ");
	}

	credentials.body  = JSON.stringify(data)

	return fetch(API_ROOT + endpoint, credentials)
	   .then(handleSucces)
	   .then(validateResponse)
	   .catch(handleErrors)

};

//---------------------------------------------------------------------------------------

function getCredentials(token, type){
	return {
		method:type,
		headers:{
			'Access-Control-Allow-Origin':'*',
			'Accept':'application/json',
			'Content-Type':'application/json',
			'Authorization':'Bearer ' + token
		}
	}
}

//---------------------------------------------------------------------------------------

export const apiPostForm = async (endpoint, formData) => {
	console.log (" ApiGateway.js > ------------------------------------------");
	console.log (" ApiGateway.js > formData = " , formData);

	const token       = await validateToken();
	return fetch(API_ROOT + endpoint, {
		method:'POST',
		headers:{
			'Authorization':'Bearer ' + token
		},
		body:formData
	})
	   .then((res) => {
		   return res
	   })

	   .then(handleSucces)
	   .then(validateResponse)
	   .catch(handleErrors)

};

