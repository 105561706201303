/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23-02-2019.
 */
import React, { Component } from 'react';
import LoginForm from "../auth/LoginPage";
import { Link } from "react-router-dom";
import { Button } from "antd";


export default class UserLogin extends Component {

	render(){

		return (
		   <div className="col-10 m-auto col-md-5 ">
			   <div className=" content-box " style={{background:"white", padding:"2rem", marginTop:50}}>
				   <div>
					   <h1>
						   <b>
							   Login to FineWires
						   </b>
					   </h1>
					   <p>New to FineWires?
					   <Link to="/login/signup">
						   <u style={{paddingLeft:5}}>Create account</u>
					   </Link>
					   </p>

				   </div>

				   {this.props.loginForm}

				   <div className="text-center">
				   <p style={{paddingTop:10}}><Link to="/login/forgot"><u>Forgot password</u></Link> </p>
				   </div>
			   </div>
		   </div>
		)
	}

}
