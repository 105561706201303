/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 17-12-2018.
 */
import React, { Component } from 'react';
import { Icon } from "antd";
import { PRIMARY_COLOR } from "../Constants";


export default class Spinner extends Component {



	render(){

		const h = this.props.height || "100%";
		const fontSize = this.props.fontSize || 60;
		const color = this.props.color || PRIMARY_COLOR;
		const label = this.props.label || "";

		return (

		   <div className=" d-flex justify-content-center align-self-center " style={{ height:h, ...this.props.style}}>
			   <div className="align-self-center text-center">
				   <Icon type="loading" style={{fontSize:fontSize, color:color}} spin/>

					   <div style={{marginTop:15}}><b>{label}</b></div>

			   </div>
		   </div>
		)

	}

}
