/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28-05-2019.
 */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import LoginFormErrorMsg from "../auth/LoginFormErrorMsg";
import { Auth } from "aws-amplify";
import { validate } from "email-validator";



export default class NewPassword extends Component {


	state = {
		errorMsg:"",
		loading:false,
		completed:false
	}

	validate = values => {

		let errors = {};

		if (this.state.errorMsg){

			const changed = this.state.name === values.email && this.state.pass === values.password
			this.setState({errorMsg:""})

		}

		if (!values.email){
			errors.email = 'Email is Required';
		}

		if (!validate(values.email)){
			errors.email = 'Invalid email format';
		}

		let passwordErrors = "";

		if (!values.password){
			passwordErrors  = 'A password is Required - ';
			errors.password = passwordErrors
			return errors
		}

		if (!/[a-z]/.test(values.password)){
			passwordErrors += ' it must contain lowercase,';
		}

		if (!/[A-Z]/.test(values.password)){
			passwordErrors += ' it must contain uppercase,';
		}

		if (!/[0-9]/.test(values.password)){
			passwordErrors += ' it must contain number,';
		}

		if (values.password.length < 8){
			passwordErrors += ' it must be more than 8 characters long';
		}

		if (passwordErrors !== ""){
			errors.password = passwordErrors
		}

		return errors;
	};



	onSubmit = (values) => {


		const email = values.email;
		const code = values.code;
		const new_password = values.password;

		console.log (" NewPassword.js > email, code, new_password = " , email, code, new_password);

		this.setState({loading:true})

		Auth.forgotPasswordSubmit(email, code, new_password)
		   .then((data) => {

			   console.log(" ForgotPassword.js > forgot submit = ", data);
			   this.setState({loading:false, completed:true})
		   })
		   .catch((err) => {
			   console.log(" ForgotPassword.js > err = ", err);
			   this.setState({errorMsg:err.message, loading:false})
		   });
	}






	render(){

		return (
		   <div className="col-10 m-auto col-md-5 ">
			   <div className=" content-box " style={{background:"white", padding:"2rem", marginTop:50}}>


				   {this.state.completed ?

					  <div className=" ">
						  <h1>
							  <b>
								  You password has been updated
							  </b>
						  </h1>
						  <div className="text-center">
							  <p style={{paddingTop:10}}><Link to="/login"><u>Log in here</u></Link></p>
						  </div>

					  </div>

				   		:

				   		 <div className="inputfields">
								 <h1>
									 <b>
										 Your verification code and new password
									 </b>
								 </h1>

								 <Formik
									initialValues={{code:"", email:"", password:""}}
									validate={this.validate}
									onSubmit={this.onSubmit}
								 >
									 {(bag) => {

										 return (
											<Form>
												<Field placeholder="Your verification code" type="code" name="code"/>
												<ErrorMessage name="code" component={LoginFormErrorMsg}></ErrorMessage>

												<Field placeholder="Your email" type="email" name="email"/>
												<ErrorMessage name="email" component={LoginFormErrorMsg}></ErrorMessage>

												<Field type="password" name="password" placeholder="New password"/>
												<ErrorMessage name="password" component={LoginFormErrorMsg}/>

												<Button block type="primary" htmlType="submit"
														style={{marginTop:10, height:45}}>Reset</Button>

												{this.state.errorMsg ?

												   <div className="d-flex justify-content-center">
													   <LoginFormErrorMsg>{this.state.errorMsg}</LoginFormErrorMsg>
												   </div>

												   : null}




												<div className="d-flex flex-row justify-content-between">
													<p style={{paddingTop:10}}><Link to="/login"><u>Log in
														instead</u></Link></p>
													<p style={{paddingTop:10}}>
														<Link to="/login/forgot">
															<u>
																Request new code
															</u>
														</Link>
													</p>
												</div>



											</Form>
										 )
									 }}
								 </Formik>
							 </div>

				   		}
			   </div>
		   </div>
		)
	}

}
