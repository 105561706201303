/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 25-02-2019.
 */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button } from "antd";

export default class ValidateEmail extends Component {

	render(){

		return (
		   <div className="col-10 m-auto col-md-5 ">
			   <div className=" content-box " style={{background:"white", padding:"2rem", marginTop:50}}>
				   <div>
					   <h1>
						   <b>
							   Please check your inbox.
						   </b>
					   </h1>
					   <p>
						   You need to verify your email before you can continue.
					   </p>

					   <Link to="/login">
						   <Button block type="primary" style={{marginTop:10, height:45}}>Click here to login </Button>
					   </Link>

				   </div>
			   </div>
		   </div>
		)
	}

}
